<template>
  <div class="shadow-md h-16 border-b-2 w-full bg-white z-50">
    <div class="flex justify-between h-full px-5 container mx-auto">
      <div class="my-auto"><router-link :to="{name:'home'}"><img src="sitemedia/img/Home_Button.png" /></router-link></div>
      <div class="my-auto flex flex-row gap-10">
        <button>
          <Search24 class="my-auto" />
        </button>
        <router-link :to="{name: 'login'}" class="flex gap-1"><User24 class="my-auto" />Login</router-link>
        <button class="flex gap-1"><Globe24 class="my-auto" />English</button>
        <button><Cart24 class="my-auto" /></button>
      </div>
    </div>
  </div>
</template>
<script>
import Search24 from "@carbon/icons-vue/es/search/20";
import Cart24 from "@carbon/icons-vue/es/shopping--cart/20";
import Globe24 from "@carbon/icons-vue/es/wikis/20";
import User24 from "@carbon/icons-vue/es/user/20";
export default {
  components: {
    Search24,
    Cart24,
    Globe24,
    User24
  }
};
</script>
