<template>
  <div id="app" class="relative">
    <navbar class=""></navbar>
    <router-view class=""></router-view>
    <Footer class=""></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/NavBar.vue";
import Footer from "@/components/FooterComp.vue";
export default {
  components: {
    Navbar,
    Footer
  }
};
</script>
<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
