<template>
    <div class="w-full sticky -bottom-52">
        <div class="h-12 bg-cw-red">

        </div>
        <div class="bg-cw-grey h-52">
            <div class="grid grid-cols-4  container mx-auto p-4">
                <div class="flex flex-col gap-1">
                    <span v-for="col in col1" :key="col" class="font-bold">{{ col }}</span>
                </div>
                <div class="flex flex-col gap-1">
                    <span v-for="col in col2" :key="col" class="font-bold">{{ col }}</span>
                </div>
                <div class="flex flex-col gap-1">
                    <span v-for="col in col3" :key="col" class="font-bold">{{ col }}</span>
                </div>
                <div class="flex flex-row gap-5 align-start">
                    <button class="h-min"><Facebook32/></button>
                    <button class="h-min"><Twitter32/></button>
                    <button class="h-min"><Instagram32/></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import Facebook32 from "@carbon/icons-vue/es/logo--facebook/32"
import Twitter32 from "@carbon/icons-vue/es/logo--twitter/32"
import Instagram32 from "@carbon/icons-vue/es/logo--instagram/32"

import {ref} from "vue"

const col1 = ref(
    [
        'Products List',
        'Promotions',
        'Register',
        'About Canadawide',
        'Contact Us',
        'Customer Service',
    ]
) 
const col2 = ref(
    [
        'Privacy Policy',
        'Copyright Information',
        'Careers',
        'FAQ'
    ]
) 
const col3 = ref(
    [
        'Sales & Customer Service',
        'Phone 800-267-2362 or 613-736-8811',
        'Fax 800-814-5162 or 613-736-0150',
        'Email cwsales@canadawide.ca'
]
) 

</script>