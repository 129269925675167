<template>
    <Products/>
</template>

<script>
// @ is an alias to /src
import Products from '@/components/ProductsPage.vue'

export default {
  name: 'HomeView',
  components: {
    Products
  }
}
</script>
