<template>
  <div class="border min-h-[408px] shadow-md h-full bg-cw-grey">
    <div class="flex flex-col h-full">
      <div v-if="item.src">
        <img
          class="hover:ring-1 focus:ring-2 w-full"
          :src="[`sitemedia/img/${item.src}`]"
        />
      </div>
      <div class="p-3 text-left h-full self-bottom relative">
        <span class="absolute bottom-4 text-xl flex gap-2" v-if="item.title && item.title != ''">{{ item.title }} <ArrowRight24 class="my-auto"/></span>
      </div>
    </div>
  </div>
</template>
<script>
import ArrowRight24 from "@carbon/icons-vue/es/arrow--right/24"
export default {
  props: ["item"],
  components: {
    ArrowRight24
  }
};
</script>
