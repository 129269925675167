<template>
  <div class="border shadow-md bg-cw-grey flex flex-col h-full">
    <div v-if="item.src" class="relative">
      <img
        class="hover:ring-1 focus:ring-2"
        :src="[`sitemedia/products/${item.src}`]"
        @error="setAltImg($event)"
      />
      <div
        v-if="item.staffPick"
        class="bg-cw-red text-white text-xs absolute bottom-0 font-medium p-1"
      >
        Staff's Pick
      </div>
    </div>
    <div class="p-3 grid grid-rows-4 gap-4 h-full">
      <div class="row-span-2 text-left flex flex-col gap-1">
        <span class="text-xs text-cw-red font-medium">{{ item.saleText }}</span>
        <span v-if="item.price && item.price != ''" class="font-medium"
          >${{ item.price }}/each</span
        >
        <span class="font-semibold">{{ item.title }}</span>
      </div>
      <div class="row-span-1 text-left flex flex-col gap-1">
        <span class="text-sm font-semibold">{{ item.brand }}</span>
        <span class="text-[0.6rem]">{{ item.serial }}</span>
      </div>
      <div class="row-span-1 flex flex-row justify-between">
        <div>
          <button v-if="item.bookmark" class="bg-cw-grey-dark text-white p-2 mr-2"><Bookmark16></Bookmark16></button>
          <button v-if="item.compare" class="bg-cw-grey-dark text-white p-2"><Compare20></Compare20></button>
        </div>
        <div>
            <button v-if="item.cart" class="bg-cw-red text-white p-2"><Cart20></Cart20></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bookmark16 from "@carbon/icons-vue/es/bookmark--add/20"
import Compare20 from "@carbon/icons-vue/es/compare/20"
import Cart20 from "@carbon/icons-vue/es/shopping--cart--plus/20"
export default {
  props: ["item"],
  components: {
    Bookmark16,
    Compare20,
    Cart20
  },
  methods: {
    setAltImg(e) {
      console.log("HIT");
      e.target.src = "sitemedia/products/scope.png";
    }
  }
};
</script>
