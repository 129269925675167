<template>
  <div class="container mx-auto">
    <Banner source="sitemedia/img/banner1.png"></Banner>
    <div
      v-for="section in sections"
      :key="section.title"
      class="lg:mx-52 my-16"
    >
      <div
        v-if="section.title && section.title != ''"
        class="flex flex-row py-5"
      >
        <button
          class="text-2xl font-medium flex p-2 gap-2 hover:ring-1 focus:ring-2 rounded-md"
        >
          {{ section.title }} <ArrowRight24 class="my-auto" />
        </button>
      </div>
      <!-- Handling sections and grid here, cards are component -->
      <div
        v-if="section.items && section.items.length != 0"
        :class="{ [`grid-cols-${section.cols}`]: section.cols }"
        class="grid gap-4"
      >
        <div
          :class="{ [`col-span-${item.span}`]: item.span }"
          v-for="item in section.items"
          :key="item.title"
        >
          <component :is="item.type" :item="item"></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArrowRight24 from "@carbon/icons-vue/es/arrow--right/24";
import ProductCard from "./ProdCard.vue";
import CatCard from "./CatCard.vue";
import Banner from "./BannerComponent.vue"
export default {
  components: {
    ArrowRight24,
    ProductCard,
    CatCard,
    Banner
  },
  data() {
    return {
      //fake data
      sections: [
        {
          title: "Recent",
          cols: 4,
          items: [
            {
              title: "PYREX® Beakers, Griffin, Heavy Duty, Graduated",
              span: 1,
              src: "beaker.png",
              type: "ProductCard",
              price: 32.64,
              saleText: "",
              staffPick: true,
              compare: true,
              bookmark: true,
              cart: true,
              brand: 'Brand',
              serial: '34234-gdf'
            },
            {
              title: "Nylon Gloves",
              span: 1,
              src: "gloves.png",
              type: "ProductCard",
              price: 43534.64,
              saleText: "PRIVATE SALE",
              staffPick: true,
              compare: true,
              bookmark: true,
              cart: false,
              brand: 'Brand',
              serial: '34234-h11'
            },
            {
              title: "Frigimat Dry Ice Maker ",
              span: 1,
              src: "dryice.png",
              type: "ProductCard",
              saleText: "ON SALE",
              price: null,
              staffPick: false,
              compare: false,
              bookmark: true,
              cart: true,
              brand: 'Brand',
              serial: '3425-534'
            },
            {
              title: "Burner of the bunsen variety",
              span: 1,
              src: "chiekcn.ort",
              type: "ProductCard",
              price: 2139.64,
              saleText: "",
              staffPick: true,
              compare: true,
              bookmark: false,
              cart: true,
              brand: 'Brand',
              serial: '34234'
            }
          ]
        },
        {
          title: "Category",
          cols: 5,
          items: [
            { title: "Beaker", span: 1, src: "beaker.png", type: "CatCard" },
            { title: "Gloves", span: 1, src: "beaker.png", type: "CatCard" },
            {
              title: "Microscope",
              span: 1,
              src: "beaker.png",
              type: "CatCard"
            },
            { title: "Burner", span: 1, src: "beaker.png", type: "CatCard" },
            { title: "Bottle", span: 1, src: "beaker.png", type: "CatCard" }
          ]
        },
        {
          title: "",
          cols: 4,
          items: [
            {
              title: "PYREX® Beakers, Griffin, Heavy Duty, Graduated",
              span: 1,
              src: "beaker.png",
              type: "ProductCard",
              price: 32.64,
              saleText: "",
              staffPick: true,
              compare: true,
              bookmark: true,
              cart: true,
              brand: 'Brand',
              serial: '34234-gdf'
            },
            {
              title: "Nylon Gloves",
              span: 1,
              src: "gloves.png",
              type: "ProductCard",
              price: 43534.64,
              saleText: "PRIVATE SALE",
              staffPick: true,
              compare: true,
              bookmark: true,
              cart: false,
              brand: 'Brand',
              serial: '34234-h11'
            },
            {
              title: "Frigimat Dry Ice Maker ",
              span: 1,
              src: "dryice.png",
              type: "ProductCard",
              saleText: "ON SALE",
              price: null,
              staffPick: false,
              compare: false,
              bookmark: true,
              cart: true,
              brand: 'Brand',
              serial: '3425-534'
            },
            {
              title: "Burner of the bunsen variety",
              span: 1,
              src: "chiekcn.ort",
              type: "ProductCard",
              price: 2139.64,
              saleText: "",
              staffPick: true,
              compare: true,
              bookmark: false,
              cart: true,
              brand: 'Brand',
              serial: '34234'
            },
            {
              title: "Burner of the bunsen variety",
              span: 1,
              src: "chiekcn.ort",
              type: "ProductCard",
              price: 2139.64,
              saleText: "",
              staffPick: true,
              compare: true,
              bookmark: false,
              cart: true,
              brand: 'Brand',
              serial: '34234'
            },
            {
              title: "",
              span: 2,
              src: "",
              type: "CatCard",
              price: 2139.64,
              saleText: "",
              staffPick: true,
              compare: true,
              bookmark: false,
              cart: true,
              brand: 'Brand',
              serial: '34234'
            },
            {
              title: "Burner of the bunsen variety",
              span: 1,
              src: "chiekcn.ort",
              type: "ProductCard",
              price: 2139.64,
              saleText: "",
              staffPick: true,
              compare: true,
              bookmark: false,
              cart: true,
              brand: 'Brand',
              serial: '34234'
            }
          ]
        },
        {
          title: "",
          cols: 4,
          items: [
            { title: "Beaker", span: 1, type: "ProductCard" },
            { title: "Gloves", span: 1, type: "ProductCard" },
            { title: "Microscope", span: 1, type: "ProductCard" },
            { title: "Burner", span: 1, type: "ProductCard" },
            { title: "", span: 3, type: "ProductCard" },
            { title: "Bottle", span: 1, type: "ProductCard" }
          ]
        }
      ]
    };
  }
};
</script>
