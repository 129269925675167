<template>
    <div class="container mx-auto px-20">
        <img :src="source" />
    </div>
    
</template>
<script>
export default {
    props: ['source']
}
</script>